import { Trans } from 'react-i18next';
import demoFr from '../assets/demo.fr.gif';
// import demoEn from '../assets/demo.en.gif';

const Demo = () => {
  return (
    <>
      <Trans i18nKey={'demo'} />
      <img className="mx-auto my-5" src={demoFr} alt='demo' />
    </>
  );
}

export default Demo;
