import Header from './components/Header';
import { useLocation } from 'react-router-dom';
import Router, { Routes } from './routes/_router';
import { useTranslation } from 'react-i18next';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const testTitle = pathname === '/pre-test' ? t('pre.title') : t('post.title');
  return (
    <>
      <Header
        hideTitle={pathname === '/pre-test/test' || pathname === '/post-test/test'}
        hideSubtitle={pathname !== '/pre-test' && pathname !== '/post-test'}
        hideLanguageButton={pathname === '/pre-test/test' || pathname === '/post-test/test'}
        title={pathname === '/pre-test' || pathname === '/post-test' ? t('home.title') : t('instructions.title')}
        subtitle={t('home.subtitle')}
        testTitle={testTitle}
      />
      <div className="h-fit color-light max-w-6xl mx-auto container">
        {children}
      </div>
    </>
  );
}

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes />
      </Layout>
    </Router>
  );
}

export default App;
